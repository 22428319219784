
import {defineComponent, computed, onMounted, watch, nextTick} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import KTAside from '@/views/layout/aside/Aside.vue';
import KTHeader from '@/views/layout/header/Header.vue';
//import KTFooter from '@/views/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/LayoutService';
import KTToolbar from '@/views/layout/toolbar/Toolbar.vue';
import KTScrollTop from '@/views/layout/extras/ScrollTop.vue';
import KTUserMenu from '@/views/layout/header/partials/ActivityDrawer.vue';
import KTLoader from '@/components/Loader.vue';
import {Actions} from '@/store/enums/StoreEnums';
import {MenuComponent} from '@/assets/ts/components';
import {removeModalBackdrop} from '@/core/helpers/dom';
import {reinitializeComponents} from '@/core/plugins/keenthemes';
import {
	toolbarDisplay,
	loaderEnabled,
	contentWidthFluid,
	loaderLogo,
	asideEnabled,
	subheaderDisplay,
	themeLightLogo,
	themeDarkLogo,
} from '@/core/helpers/config';

export default defineComponent({
	name: 'Layout',
	components: {
		KTAside,
		KTHeader,
		//KTFooter,
		KTToolbar,
		KTScrollTop,
		KTUserMenu,
		KTLoader,
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		
		// show page loading
		store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');
		
		// initialize html element classes
		HtmlClass.init();
		
		const pageTitle = computed(() => {
			return store.getters.pageTitle;
		});
		
		const breadcrumbs = computed(() => {
			return store.getters.pageBreadcrumbPath;
		});
		
		const pageButtons = computed(() => {
			return store.getters.getPageButtons;
		});
		const backButton = computed(() => {
			return store.getters.getBackButton;
		});
		
		onMounted(() => {
			//check if current user is authenticated
			if (!store.getters.isUserAuthenticated) {
				router.push({name: 'sign-in'});
			}
			
			nextTick(() => {
				reinitializeComponents();
			});
			
			// Simulate the delay page loading
			setTimeout(() => {
				// Remove page loader after some time
				store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
			}, 500);
		});
		
		watch(
			() => route.path,
			() => {
				MenuComponent.hideDropdowns(undefined);
				
				// check if current user is authenticated
				if (!store.getters.isUserAuthenticated) {
					router.push({name: 'sign-in'});
				}
				
				nextTick(() => {
					reinitializeComponents();
				});
				removeModalBackdrop();
			}
		);
		
		return {
			toolbarDisplay,
			loaderEnabled,
			contentWidthFluid,
			loaderLogo,
			asideEnabled,
			subheaderDisplay,
			pageTitle,
			breadcrumbs,
			pageButtons,
			backButton,
			themeLightLogo,
			themeDarkLogo,
		};
	},
});
