
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";
import KTMenu from "@/views/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
	name: "KTAside",
	components: {
		KTMenu,
	},
	props: {
		lightLogo: String,
		darkLogo: String,
	},
	computed: {
		sideLogo(){
      if(window.location.hostname === 'partners.go2app.ai') {
        return '/media/logos/g2a-logo-2.svg';
      } else if (window.location.hostname === 'partners.zilmtech.app') {
        return '/media/logos/zilmtech-logo-w.png';
      } else {
        return '/media/logos/Z2A_Digital_Logotyp_Alt_RGB_Achromatic_Black.png';
      }
		}
	},
	setup() {
		const {t} = useI18n();

		return {
			asideTheme,
			t,
		};
	},
});
