const MainMenuConfig = [
	{
		pages: [
			// {
			// 	label: 'Dashboard',
			// 	heading: 'dashboard',
			// 	route: '/dashboard',
			// 	svgIcon: 'media/icons/duotune/art/art002.svg',
			// 	fontIcon: 'bi-app-indicator',
			// },
			{
				label: 'Dashboard',
				heading: 'dashboard',
				route: '/dashboard',
				svgIcon: 'media/icons/duotune/art/art002.svg',
				fontIcon: 'bi-app-indicator',
			},
			{
				label: 'Offers',
				heading: 'offers',
				route: '/offers',
				svgIcon: 'media/icons/duotune/art/art002.svg',
				fontIcon: 'bi-app-indicator',
			},
			{
				label: 'Reports',
				heading: 'reports',
				route: '/reports',
				svgIcon: 'media/icons/duotune/art/art002.svg',
				fontIcon: 'bi-app-indicator',
			},
			{
				label: 'Conversions',
				heading: 'conversions',
				route: '/conversions',
				svgIcon: 'media/icons/duotune/art/art002.svg',
				fontIcon: 'bi-app-indicator',
			},
			{
				sectionTitle: 'Api Documentation',
				svgIcon: 'media/icons/duotune/art/art002.svg',
				fontIcon: 'bi-app-indicatior',
				sub: [
					{
						label: 'Campaign List',
						route: '/docs/get_offers'
					},
					{
						label: 'Traffic',
						route: '/docs/traffic'
					}
				]
			}
		],
	},
];

export default MainMenuConfig;
