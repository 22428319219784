
import { defineComponent } from 'vue';
import KTSearch from '@/views/layout/header/partials/Search.vue';
import KTNotificationsMenu from '@/views/layout/header/partials/NotificationsMenu.vue';
import KTQuickLinksMenu from '@/views/layout/header/partials/QuickLinksMenu.vue';
import KTUserMenu from '@/views/layout/header/partials/UserMenu.vue';
import DataService from '@/core/services/DataService';

export default defineComponent({
	name: 'topbar',
	components: {
		KTUserMenu,
	},
	data() {
		return {
			publihserName: '',
		}
	},
	methods: {
		async loadData() {
			const publisherInfo = await DataService.get('getInfo');
			this.publihserName = publisherInfo.publisher.name;
		}
	},
	created() {
		this.loadData();
	},
});
