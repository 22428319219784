
import { defineComponent } from "vue";
import Item1 from "@/views/layout/header/partials/activity-timeline/Item1.vue";
import Item2 from "@/views/layout/header/partials/activity-timeline/Item2.vue";
import Item3 from "@/views/layout/header/partials/activity-timeline/Item3.vue";
import Item4 from "@/views/layout/header/partials/activity-timeline/Item4.vue";
import Item5 from "@/views/layout/header/partials/activity-timeline/Item5.vue";
import Item6 from "@/views/layout/header/partials/activity-timeline/Item6.vue";
import Item7 from "@/views/layout/header/partials/activity-timeline/Item7.vue";
import Item8 from "@/views/layout/header/partials/activity-timeline/Item8.vue";

export default defineComponent({
  name: "kt-activity-drawer",
  components: {

  },
});
